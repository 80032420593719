/**
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

var Qrcode = (function () {
    /* ----- Init ----------------------------------------------------------------------------------------------------- */
    function Init() {

        render();
    }

    /* ----- Init ----------------------------------------------------------------------------------------------------- */
    function render() {
        if (AppData.profile.qrcode) {

            $('#qrcode .qrcode').empty();

            var qrcode = new QRCode($('#qrcode .qrcode').get(0), {
                text: AppData.profile.qrcode,
                width: 256,
                height: 256,
                colorDark: "#000000",
                colorLight: "#ffffff",
                correctLevel: QRCode.CorrectLevel.H
            });

            /*let qrcode = new QRCode({
                content : AppData.profile.qrcode,
                padding : 0,
                width : 256,
                height : 256,
                color : "#000000",
                background : "#ffffff",
                ecl : "H"
            });
            let svg = qrcode.svg();
            $('#qrcode').find('.qrcode').html(svg);*/
        }
    }

    function update() {
        $.post('api/', {
            do: 'getProfile'
        }, function (data) {
            AppData.profile = data.profile;
            Qrcode.render();
            data = null;
        });
    }

    /* ----- Public API ----------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        render: render,
        update: update
    }
})();
