var Navigation = (function () {
    'use strict';
    var
        $templateNavigationItem,
        $templateMenuItem,
        $fragmentNavigation,
        $fragmentNavigationMinimal,
        $fragmentMenu,
        navs;
    let allowCloseMenu = false;

    /* ----- Init Navigation Items ---------------------------------------------------------------------------------- */
    function Init() {

        $templateNavigationItem = $('template#navItem').contents();
        $templateMenuItem = $('template#tplMenuItem').contents();
        $fragmentNavigation = $(document.createDocumentFragment());
        $fragmentNavigationMinimal = $(document.createDocumentFragment());
        $fragmentMenu = $(document.createDocumentFragment());
        renderDetails();
        if(AppData.modulsettings.show_quickmenu) {
            setWindowWidth();
        }
    }

    function renderDetails() {
        $('nav#minimal_nav > .container').html('');
        $('nav#default_nav > .container').html('');
        $('div#landing > header > .landing_elements > #navplus > .container').html('');
        $('div#hamburger > nav > .navList').html('');

        AppData.navigation.sort(function (a, b) {
            if (a.sort_id !== undefined && b.sort_id !== undefined) {
                return parseInt(a.sort_id) > parseInt(b.sort_id) ? 1 : -1;
            } else {
                return 0;
            }
        });

        navs = AppData.navigation.filter(function(el) {
            return el.group && el.group.indexOf(AppData.profile.group) > -1;
        });

        var profile_menu = AppData.navigation.find(function (el) {
            return el.module === "profile" && el.visible && el.group && el.group.indexOf(AppData.profile.group) > -1;
        });

        if (profile_menu === undefined) {
            $("#hamburger .profile").hide();
        } else {
            $("#hamburger .profile").show();
        }

        $.each(AppData.navigation || [], function (i, el) {
            var
                isMenuIn,
                isMenuInGroup,
                $cloneNav = $templateNavigationItem.clone(),
                $cloneNavMinimal = $templateNavigationItem.clone(),
                $cloneMenu = $templateMenuItem.clone();
            $.each([$cloneNav, $cloneMenu, $cloneNavMinimal], function (j, $clone) {
                $clone.find('.open-main').attr('data-target', el.module);
                $clone.find('.open-main').attr('data-id', el.id);
                if (el.extra_class != "" && el.extra_class != undefined) {
                    $clone.find('.open-main').addClass(el.extra_class || "").removeClass("color-primary");
                }
                if(el.module === "broadcast") {
                    $clone.find('.open-main').addClass('broadcast');
                    if(AppData.modulsettings.showredalert) {
                        $clone.find('.open-main').removeClass("color-primary").addClass('blinkingicon');
                        $clone.find('.open-main').css("color", AppData.settings.colorPrimary);
                    }
                }
                if(el.module === "soundchannels") {
                    $clone.find('.open-main').addClass('soundchannels');
                }
                if (el.target) $clone.find('.open-main').attr('data-targetid', el.target);
                if (el.image && (el.image_on_normal || el.image_on_quick)) {
                    let image = AppData.medias.find(function (item) {
                        return item.id == el.image;
                    });

                    if (image) {
                        $clone.find(".data-img").attr("src", image.files[localState.lang].file);
                    }
                }

                $clone.toggleClass("img_normal", el.image_on_normal == true);
                $clone.toggleClass("img_quick", el.image_on_quick == true);

                if (el.icon == undefined || el.icon == "") {
                    var icon = AppData.modules.filter(function (e) {
                        return e.id == el.module;
                    });
                    if (icon.length > 0) {
                        $clone.find('.data-icon').addClass(icon[0].icon);
                        $clone.find('.open-main').attr('data-icon', icon[0].icon);
                        $cloneMenu.attr('data-icon', icon[0].icon);
                    }
                } else {
                    $clone.find('.data-icon').addClass(el.icon);
                    $clone.find('.open-main').attr('data-icon', el.icon);
                    $cloneMenu.attr('data-icon', el.icon);
                }
                $clone.find('.data-title').text(el.name[localState.lang]);
                isMenuIn = AppData.profile.menus.filter(function(e) {
                    return e.id === el.id;
                });
                isMenuInGroup = navs.filter(function(e) {
                    return e.id === el.id;
                });
                $clone.toggleClass('hidden', !(String(el.visible) === 'true' && (isMenuIn.length > 0 ? isMenuIn[0].show === '1' : isMenuInGroup.length > 0)));

                if(el.icon_blink_icon) {
                    $clone.find('.open-main').attr("style", "background-color: " + el.icon_blink_icon);
                }
                if(!$clone.find('.open-main').hasClass("blink_icon")) {
                    $clone.find(".open-main").attr("style", "background-color: none")
                }
            });
            $cloneMenu.attr('data-target', el.module);
            $cloneMenu.attr('data-id', el.id);
            $cloneMenu.attr('data-targetid', el.target);
            if(!el.only_in_minimalistic_menu) {
                $cloneNav.appendTo($fragmentNavigation);
            }
            if(el.show_in_minimalistic_menu) {
                $cloneNavMinimal.appendTo($fragmentNavigationMinimal);
            }
            $cloneMenu.appendTo($fragmentMenu);
            $cloneNav = $cloneMenu = null;

        });
        $fragmentMenu.appendTo('div#hamburger > nav > .navList');

        $(".navplusbutton").remove();
        $("#default_nav .closemenu").remove();

        var $cloneNav  = $templateNavigationItem.clone();
        $cloneNav.find('.data-icon').addClass("fas fa-plus");
        $cloneNav.find('.data-title').html(Language.getItem("menu"));
        $cloneNav.find('.data-title').removeClass("color-icon-text").addClass("color-primary");
        $cloneNav.addClass('navplusbutton');
        // $cloneNav.appendTo($fragmentNavigation);
        $cloneNav.appendTo($(".bodycontent"));

        var $cloneClose  = $templateNavigationItem.clone();
        $cloneClose.find('.data-icon').addClass("fas fa-plus");
        $cloneClose.addClass('closemenu');
        $cloneClose.appendTo($fragmentNavigation);

        if (AppData.settings.homeScreen === "classic") {
            $cloneNav.addClass('hidden');
            $cloneClose.addClass('hidden');
        }

        $fragmentNavigationMinimal.appendTo('nav#minimal_nav > .container');
        $fragmentNavigation.appendTo('nav#default_nav > .container');
        $('nav#minimal_nav > .container .wrapper').find(".data-title").remove();
        $("div#landing nav").show();
        if ((AppData.settings.homeScreen === "floating") && !$("#default_nav").hasClass("opened")) {
            $("nav#default_nav .wrapper").not(".navplusbutton").hide();
        }

        if ((AppData.settings.homeScreen == "floating") && AppData.settings.show_menu_on_load == true) {
            showMenu();
        }

        if(AppData.settings.blink_menu_icon) {
            $(".navplusbutton").children().addClass("blink_button").removeClass("color-primary");
        } else {
            $(".navplusbutton").children().removeClass("blink_button").addClass("color-primary");
        }
        setMinimalisticMenu();
        $('nav#minimal_nav > .container .wrapper').find(".open-main").removeClass("color-primary");
        $('nav#minimal_nav').toggle(AppData.modulsettings.show_quickmenu);
        $('nav#default_nav').show();
        // delete Navigation.Init;
        if (AppData.settings.homeScreen == "floating") {
            $(".navplusbutton").toggleClass("hidden", AppData.settings.hidemenu === true);
        }
    }


    /* ----- Change Navigation Items -------------------------------------------------------------------------------- */
    function BuildFromSource() {
        $.post('api/', {
            do: 'getNavigation'
        }, function (data) {
            AppData.navigation = data.nav;
            AppData.profile.menus = data.user_menus;
            $.each(AppData.navigation || [], function (i, el) {
                if ('visible' in el) {
                    Layout._eachInitNavigation(i, el);
                }
            });
            renderDetails();
            data = null;
        })
    }

    $(document).on('mouseup touchend', ".navplusbutton .open-main, #default_nav .closemenu", function(e) {
        allowCloseMenu = true;
        if($("#default_nav").hasClass("opened")) {
            hideMenu();
            if(AppData.settings.blink_menu_icon) {
                $(".navplusbutton").children().delay(500).queue(function() {
                    $(".navplusbutton").children().clearQueue().removeClass("color-primary").addClass("blink_button");
                });
            } else {
                $(".navplusbutton").children().removeClass("blink_button").addClass("color-primary");
            }
        } else {
            $(".navplusbutton").children().removeClass("blink_button").addClass("color-primary");
            showMenu();
        }
    });

    $(document).on('mouseup touchend', ".mute-video-button", function(e) {
        e.preventDefault();
        var videoplayer = videojs('my-video-bg');
        if($(".mute-video-button i").hasClass("fa-volume-mute")) {
            videoplayer.muted(false);
            $(".mute-video-button i").removeClass("fa-volume-mute").addClass("fa-volume-up");
        } else {
            videoplayer.muted(true);
            $(".mute-video-button i").removeClass("fa-volume-up").addClass("fa-volume-mute");
        }
    });

    function showMenu() {
        if (AppData.settings.homeScreen == "floating") {
            //$(".navplusbutton i").removeClass("fa-plus").addClass("fa-minus");
            //$("#default_nav .wrapper").show().removeClass("animated fadeOut").addClass("animated fadeIn");
            $("#default_nav .wrapper").fadeIn(400);
            $("#default_nav").addClass("opened");
            var rgbcolor = hexToRgb(AppData.settings.colorMenuBackground);

            let menuopacity = AppData.settings.menuOpacity || 50;

            if (rgbcolor !== undefined && rgbcolor !== null) {
                $("#default_nav").addClass("blur").css("background-color", 'rgba(' + rgbcolor.r + ',' + rgbcolor.g + ',' + rgbcolor.b + ', '+(menuopacity / 100)+')')
            }
        }
    }

    function hideMenu() {
        if ((AppData.settings.homeScreen == "floating") && allowCloseMenu) {
            //$(".navplusbutton i").removeClass("fa-minus").addClass("fa-plus");
            //$("#default_nav .wrapper").removeClass("animated fadeIn").addClass("animated fadeOut").hide();
            $("#default_nav").addClass("closing");
            $("#default_nav .wrapper").fadeOut(400, function (){
                $("#default_nav").removeClass("opened closing");
                $(".navplusbutton").fadeIn(400);
                $("#default_nav").css("background-color", '').removeClass("blur");
            });
            //$(".navplusbutton").show().removeClass("animated fadeOut").addClass("animated fadeIn");

        }
    }

    function setAllowCloseMenu() {
        allowCloseMenu = true;
    }

    function setMinimalisticMenu() {
        setWindowWidth();
    }

    function setWindowWidth() {
        let article = $("article").not(".info"),
            menuHeightWidth = 45,
            clientWindowWidth = 780,
            windowWidth = $(window).width(),
            width = $(window).width() - menuHeightWidth;

        if(AppData.modulsettings.show_quickmenu) {

            let quickmenu = $("#minimal_nav").find(".container");
            let quickmenu_pos = 'quickmenu_pos' in AppData.modulsettings && AppData.modulsettings.quickmenu_pos !== "" ? AppData.modulsettings.quickmenu_pos : "top";
            quickmenu.removeClass("top left right").addClass(quickmenu_pos);
            setQuickMenuColors();

            if (windowWidth < clientWindowWidth) {
                switch (quickmenu_pos) {
                    case "left":
                        article.css("width", width + "px").css("left", menuHeightWidth + "px").css("top", "0").css("right", "0");
                        break;
                    case "right":
                        article.css("width", width + "px").css("left", "0").css("top", "0").css("right", menuHeightWidth + "px");
                        break;
                    case "top":
                        article.css("width", windowWidth + "px").css("left", "0").css("top", menuHeightWidth + "px").css("right", "0");
                        break;
                }
            } else {
                article.css("width", "");
            }

            setQuickMenuColors();
            /*if (localState.layout.length > 0 && windowWidth < clientWindowWidth) {
                $("#minimal_nav").find(".container").css("background-color", "#ffffff");
            } else {
                setQuickMenuColors();
            }*/
        } else {
            article.css("width", "").css("left", "0").css("top", "0").css("right", "0");
        }
    }

    $( window ).resize(function() {
        setWindowWidth();
    });

    function setQuickMenuColors() {
        let menuBg = hexToRgb('quickmenu_bg_color' in AppData.modulsettings && AppData.modulsettings.quickmenu_bg_color !== "" ? AppData.modulsettings.quickmenu_bg_color : "#000000");
        let menuOp = ('quickmenu_transparency' in AppData.modulsettings && AppData.modulsettings.quickmenu_transparency !== "") ? AppData.modulsettings.quickmenu_transparency / 100 : "100";
        $("#minimal_nav").find(".container").css('color', AppData.modulsettings.quickmenu_icon_color).css("background-color", 'rgba(' + menuBg.r + ',' + menuBg.g + ',' + menuBg.b + ', ' + menuOp + ')');
    }

    /* ----- Public API ----------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        BuildFromSource: BuildFromSource,
        showMenu: showMenu,
        hideMenu: hideMenu,
        setAllowCloseMenu: setAllowCloseMenu,
        setMinimalisticMenu: setMinimalisticMenu,
        setQuickMenuColors: setQuickMenuColors
    };
})();
