/*
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */
var Wordcloud = (function () {
    'use strict';

    /* ----- Init ----------------------------------------------------------------------------------------------------- */
    function Init(wcdata) {
        if (wcdata.id) {
            $.post('api/', {
                do: 'getWordcloud',
                survey: wcdata.id,
                type: wcdata.type
            }, function (data) {
                var wc = [];
                var maxcount = 0;

                for (var i in data) {
                    if (maxcount < data[i].wc_count) {
                        maxcount = data[i].wc_count;
                    }
                }

                for (var w in data) {
                    var c = data[w].wc_count / maxcount;
                    wc.push([
                        wcdata.type == "quiz" ? data[w][localState.lang].text : data[w].text,
                        isNaN(c)? 0 : c
                    ]);
                }

                var colors = ['#f05545','#b71c1c', '#7f0000', '#c09292'];

                Layout.show('wordcloud');

                WordCloud($('#wordcloud div')[0], {
                    list: wc,
                    drawOutOfBound: true,
                    shrinkToFit: true,
                    //gridSize: Math.round($('#wordcloud canvas').width()),
                    weightFactor: function (size) {
                        //return (Math.pow(size, 2.2) * $('#wordcloud canvas').width() / 1920) * 3;
                        return (size * 60) + 30;
                    },
                    fontFamily: 'Trebuchet MS, Helvetica, sans-serif',
                    color: function (word, weight) {
                        return colors[colors.length * Math.random() | 0]
                        // if (weight >= 0.7) {
                        //     return '#f05545'
                        // } else if (weight >= 0.5) {
                        //     return '#b71c1c'
                        // } else if (weight >= 0.3) {
                        //     return '#7f0000'
                        // } else {
                        //     return '#c09292'
                        // }
                    },
                    rotateRatio: 0.8,
                    backgroundColor: '#ffffff'
                });
                data = null;

                $("#wordcloud div").on("wordclouddrawn", function () {
                    let fadein = 0;
                    $("#wordcloud div span").each(function () {
                        let _this = $(this);
                        setTimeout(function () {
                            _this.addClass("show");
                        }, fadein);
                        fadein += 50;
                    });
                });
            });
        }
    }

    /* ----- Public Api ----------------------------------------------------------------------------------------------- */
    return {
        Init: Init
    }
})();