var Agenda = (function () {
    'use strict';
    /* ----- Declare Variables ---------------------------------------------------------------------------------------- */
    var
        agendaTab,
        months,
        days,
        $templateAgendaItem,
        $templateAgendaVideo,
        $templateAgendaSpeaker,
        $templateAgendaDoc,
        $templateAgendaSurvey;

    /* ----- Init ----------------------------------------------------------------------------------------------------- */
    function Init() {
        $templateAgendaItem = $('template#tplAgendaItem').remove().contents();
        $templateAgendaVideo = $('template#tplAgendaVideo').remove().contents();
        $templateAgendaSpeaker = $('template#tplAgendaSpeaker').remove().contents();
        $templateAgendaDoc = $('template#tplAgendaDoc').remove().contents();
        $templateAgendaSurvey = $('template#tplAgendaSurvey').remove().contents();

        months = Language.getItem('months') || [];
        days = Language.getItem('days') || [];

        $(document)
            .on('show', '#agenda', evtShowLayout)
            .on('change', '#agenda select.date-selector', evtChange)
            .on('click', '#agenda #agendaList .item:not(.breaking)', evtClick)
            .on('click', '#agenda #agendaListTab .item:not(.breaking)', evtClick)
            .on('click', '#agenda-details .childrenagenda .item:not(.breaking)', evtSubClick)
            .on('click', '#agenda-subdetails .childrenagenda .item:not(.breaking)', evtSubSubClick)
            .on('click', '#agenda #speakersList .item', evtClick)
            .on('click', '#agenda-details .speaker', Speakers.evtClickDetails)
            .on('click', '#agenda-subdetails .speaker', Speakers.evtClickDetails)
            .on('click', '#agenda-subsubdetails .speaker', Speakers.evtClickDetails)

            .on('click', '#agenda-details .video', showVideo)
            .on('click', '#agenda-subdetails .video', showVideo)
            .on('click', '#agenda-subsubdetails .video', showVideo)

            .on('click', '#agenda-details .new-issue', newIssueClick)
            .on('click', '#agenda-subdetails .new-issue', newIssueClick)
            .on('click', '#agenda-subsubdetails .new-issue', newIssueClick)
            .on('click', '#agenda-details .new-note', newNoteClick)
            .on('click', '#agenda-subdetails .new-note', newNoteClick)
            .on('click', '#agenda-subsubdetails .new-note', newNoteClick)
            .on('click', '#agenda-details .doc', Docs.evtClickDetails)
            .on('click', '#agenda-subdetails .doc', Docs.evtClickDetails)
            .on('click', '#agenda-subsubdetails .doc', Docs.evtClickDetails)
            .on('click', '#agenda-details .survey', Surveys.evtClickDetails)
            .on('click', '#agenda-subdetails .survey', Surveys.evtClickDetails)
            .on('click', '#agenda-subsubdetails .survey', Surveys.evtClickDetails)
            .on('click', '#agenda-details .favorite_agenda', favoriteAgenda)
            .on('click', '#agenda-subdetails .favorite_agenda', favoriteAgenda)
            .on('click', '#agenda-subsubdetails .favorite_agenda', favoriteAgenda)
            .on('click', '#agenda .agenda_day_tab', changeDate);

        toggleRequestSpeakerGotoLink();

        Build();

        delete Agenda.Init;
    }

    function restructureAgenda() {
        agendaTab = {};
        for (var i = 0; i < AppData.agenda.length; i++) {
            var item = AppData.agenda[i];
            let days = item.day;
            if (typeof item.day === "string") {
                days = [item.day];
            }

            for (let d = 0; d < days.length; d++) {
                if (agendaTab[days[d]] == undefined) {
                    agendaTab[days[d]] = {};
                }
                var channel = "no_channel";
                if (agendaTab[days[d]][channel] == undefined) {
                    agendaTab[days[d]][channel] = [];
                }
                agendaTab[days[d]][channel].push(item);
            }
        }
    }

    function changeDate() {

        $(".agenda_day_tab").removeClass("active bg-primary");
        $(this).addClass("active bg-primary");

        localState.agenda = {
            dayIndex: $(this).attr("data-dayid") || 0,
            itemIndex: 0
        };

        renderList()
    }

    function toggleRequestSpeakerGotoLink() {

        $('#agenda-details .speakersList .item').each(function (i, item) {
            if(AppData.modulsettings.showspeakergotolink === true) {
                $(item).addClass("link");
                $(item).find(".chevron").show();
            } else {
                $(item).removeClass("link");
                $(item).find(".chevron").hide();
            }
        });
    }

    function showDetails(id) {
        writeDbLog("show details", id, "user_logs", "agenda");
        if (id) {
            if (localState.agenda != undefined) {
                if (id !== localState.agenda.itemIndex || localState.agenda.itemIndex === 0) {
                    localState.agenda.itemIndex = String(id);
                    localState.agendadetails = String(id);
                    BuildDetails("1");
                }
            } else {
                localState.agenda = { 'itemIndex': String(id)};
            }
            Layout.show('agenda-details');
        }
    }

    /* ----- Build from Source ---------------------------------------------------------------------------------------- */
    function BuildFromSource(selectedDay) {
        $.post('api/', {
            do: 'getAgenda'
        }, function (data) {
            AppData.agenda = data;
            Build(selectedDay);
            FavoriteAgenda.Build();
            Issues.BuildFromSource();
            data = null;
        })
    }

    function BuildFromSourceWidthSelectedDay() {

        $.post('api/', {
            do: 'getDays'
        }, function (data) {
            AppData.days = data;

            let selected_date = $('#agenda select.date-selector option:selected').val();
            if (AppData.modulsettings.agendatabs) {
                selected_date = $('#agenda .agenda_day_tab.active').attr("data-dayid");
            }
            BuildFromSource(selected_date);
        })

    }

    /* ----- Build ---------------------------------------------------------------------------------------------------- */
    function Build(selectedDay) {

        sortAgenda();
        sortDays();

        if (AppData.modulsettings.agendatabs) {
            restructureAgenda();
        }

        BuildDates(selectedDay);
        renderList();

        if($("#agenda-details").css("visibility") !== "hidden") {
            BuildDetails('2');
            BuildDetails('1');
        }
        evtShowLayout();
    }

    /* ----- Sort List ------------------------------------------------------------------------------------------------ */
    function sortAgenda() {
        (AppData.agenda || []).sort(function (a, b) {
            if (a.date == b.date) {
                return (a.timeStart < b.timeStart) ? -1 : (a.timeStart > b.timeStart) ? 1 : 0;
            } else {
                return (a.date < b.date) ? -1 : 1;
            }
        });
    }

    /* ----- Sort List ------------------------------------------------------------------------------------------------ */
    function sortDays() {
        (AppData.days || []).sort(function (a, b) {
            if (AppData.modulsettings.agendasortbyorder) {
                if (a.sort_id !== undefined && b.sort_id !== undefined) {
                    return parseInt(a.sort_id) > parseInt(b.sort_id) ? 1 : -1;
                }
            } else {
                return a.date.localeCompare(b.date);
            }
        });
    }

    /* ----- Build Dates ---------------------------------------------------------------------------------------------- */
    function BuildDates(selectedDay) {
        /* Check AppData */

        if (!('days' in AppData) || !(AppData.days || []).length) {
            return false;
        }

        if (AppData.modulsettings.agendatabs) {
            $("#agenda .date-wrapper").hide();
            $("#agenda .date-wrapper-tab").show();

            $("#agenda #agendaList").hide();
            $("#agenda #agendaListTab").show();

            $("#agenda .date-wrapper-tab").html("");

            var dayTemplate = $("#agenda #agendaDayTab").contents();

            var active_days = AppData.days.filter(function(day){
                return day.active;
            });

            let c = 0;

            $.each(AppData.days, function (i, el) {
                if (el.active) {
                    var daytab = dayTemplate.clone();

                    daytab.attr("data-dayid", el.id);
                    daytab.html(el.name[localState.lang]);

                    if ((selectedDay == undefined || selectedDay == "") && c == 0) {
                        daytab.addClass("active bg-primary");
                    } else if (selectedDay == el.id) {
                        daytab.addClass("active bg-primary");
                    }

                    daytab.appendTo($("#agenda .date-wrapper-tab"));

                    daytab.css({
                        'flex': '1 1 '+(100 / active_days.length)+'%'
                    });

                    c++;
                }
            });

            if ('agenda' in localState && selectedDay != undefined && selectedDay != "") {
                localState.agenda.dayIndex = selectedDay;
            } else {
                localState.agenda = {
                    dayIndex: $('#agenda .date-wrapper-tab .agenda_day_tab.active').attr("data-dayid"),
                    itemIndex: 0
                };
            }

            return;
        }

        $("#agenda .date-wrapper").show();
        $("#agenda .date-wrapper-tab").hide();

        $("#agenda #agendaList").show();
        $("#agenda #agendaListTab").hide();

        var
            //optionsDate = [],
            $fragmentOptions = $(document.createDocumentFragment());
        /* Update localized strings  */
        months = Language.getItem('months') || [];
        days = Language.getItem('days') || [];
        /* Make Date options */
        $.each(AppData.days, function (i, el) {
            if (el.active) {

                let option = $('<option>', {
                    val: el.id,
                    text: el.name[localState.lang]
                });
                option.appendTo($fragmentOptions);

                if (el.color) {
                    option.css({
                        "color": el.color
                    });
                }

            }
        });
        $('#agenda select.date-selector').html($fragmentOptions);
        if ('agenda' in localState) {
            var
                optionsDate = $('#agenda select.date-selector option').map(function () {
                    return this.value;
                }).get(),
                selectIndex = optionsDate.indexOf((selectedDay) ? String(selectedDay) : localState.agenda.dayIndex);
            if (selectIndex === -1) {
                selectIndex = 0;
            }
            $('#agenda select.date-selector').prop('selectedIndex', selectIndex);
            localState.agenda.dayIndex = $('#agenda select.date-selector').val();
        } else {
            localState.agenda = {
                dayIndex: $('#agenda select.date-selector').val(),
                itemIndex: 0
            };
        }
        $('#agenda .date-wrapper .content').text($('#agenda select.date-selector option:selected').text());
        $fragmentOptions = null;
    }

    /* ----- Render Agenda List --------------------------------------------------------------------------------------- */
    function renderList() {
        /* Check AppData */
        if (!('agenda' in AppData) || !(AppData.agenda || []).length) {
            return false;
        }
        var
            $self = $('#agenda #agendaList'),
            adocs = $("#agenda .agendadocs"),
            state = localState.agenda,
            $fragmentAgenda = $(document.createDocumentFragment());
        /* Build List */

        let agendadocs = AppData.docs.filter(function(item){
            return item.type == "agendadoc";
        });

        adocs.empty();
        if (agendadocs.length > 0) {
            $.each(agendadocs, function (i, e){
                let docitem = $("<div/>").addClass("agendadoc");
                docitem.html('<a href="javascript:void(0);" class="color-primary">'+e.title[localState.lang]+'</a>').attr("data-docid", e.id);

                docitem.on("click", function (){
                    Docs.showDetails($(this).attr("data-docid"));
                });

                adocs.append(docitem);
            });
        }

        if (AppData.modulsettings.agendatabs) {

            $self = $('#agenda #agendaListTab');

            var list = agendaTab[state.dayIndex];
            var agendaChannelTab = $("#agenda #agendaChannelTab");

            for (var i in list) {

                var act = agendaChannelTab.contents().clone();
                var channel = AppData.channels.find(function (item) {
                    return item.channel == i;
                });
                if (channel != undefined) {
                    act.find(".agenda_channel_title").html(channel.name[localState.lang]);
                } else {
                    act.find(".agenda_channel_title").html("Other");
                }

                act.find(".agenda_channel_title").hide();

                var list_container = act.find(".agendaList");

                $.each(list[i], function (i, el) {

                    el.active = (el.active === undefined) ? true : el.active;

                    let days = el.day;
                    if (typeof el.day === "string") {
                        days = [el.day];
                    }

                    if (el.active && days.indexOf(state.dayIndex) > -1) {
                        if (!el.parent) {
                            var $clone;
                            $clone = $templateAgendaItem.clone();
                            renderRow($clone, el);
                            $clone.appendTo(list_container);
                            $clone = null;
                        }
                    }
                });

                act.appendTo($fragmentAgenda);
                var w = 100 / Object.keys(list).length;
                act.css({
                    'flex': '1 1 '+w+'%'
                });
            }
        } else {
            $.each(AppData.agenda, function (i, el) {
                el.active = (el.active === undefined) ? true : el.active;

                let days = el.day;
                if (typeof el.day === "string") {
                    days = [el.day];
                }

                if (el.active && days.indexOf(state.dayIndex) > -1) {
                    if (!el.parent) {
                        var $clone = $templateAgendaItem.clone();
                        renderRow($clone, el);
                        $clone.appendTo($fragmentAgenda);
                        $clone = null;
                    }
                }
            });
        }
        /* Append List  */
        $self.html($fragmentAgenda);
        $self = state = $fragmentAgenda = null;

        toggleItemArrows();
    }

    /* ----- Render Agenda Row ---------------------------------------------------------------------------------------- */
    function renderRow($elem, data) {
        var timeformat = AppData.translations.filter(function(e) {
            return e.translation_id === localState.lang;
        })[0];

        $elem.toggleClass('now', data.status === 'now');
        $elem.toggleClass('ended', data.status === 'ended');
        $elem.toggleClass('breaking', !data.rooms && !data.details);
        $elem.toggleClass('link', !!data.rooms || !!data.details);
        $elem.attr('data-id', data.id);
        $elem.find('.data-timeStart').text(makeTimeFormat(data.timeStart, timeformat.time_format));
        $elem.find('.data-timeEnd').text(makeTimeFormat(data.timeEnd, timeformat.time_format));
        /*var room = AppData.rooms.find( function ( el ) {
          return el.id === data.rooms
        } );*/
        var room = AppData.channels.find(function(e) {
            return e.channel === data.rooms;
        }) || false; // bungi
        $elem.find('.data-rooms')
            .attr("data-room-id", data.rooms).text(room ? room.name[localState.lang] : "")
            .parent().toggleClass('hidden', !room);

        var atitle;
        if (typeof data.title == "string") {
            atitle = data.title;
        } else {
            atitle = data.title[localState.lang];
        }

        $elem.find('.data-title').text(atitle);
        $elem.find('[data-lang=now]').text(Language.getItem('now'));
        $elem.find('[data-lang=expired]').text(Language.getItem('expired'));

        // $elem.find('.data-title').toggleClass('title-short',atitle.length < 35);
        // $elem.find('.data-title').toggleClass('title-long', atitle.length >= 70);
        // $elem.find('.data-title').toggleClass('title-extra', atitle.length >= 120);
    }

    /* ----- Build Details -------------------------------------------------------------------------------------------- */
    function BuildDetails(mode) {
        var detailsSub,
            timeformat = AppData.translations.filter(function(e) {
                return e.translation_id === localState.lang;
            })[0];

        if(mode === "1") {
            detailsSub = "";
            localState.agenda.itemIndex = localState.agendadetails;
        } else if(mode === "2"){
            localState.agenda.itemIndex = localState.agendasubdetails;
            detailsSub = "sub";
        } else {
            localState.agenda.itemIndex = localState.agendasubsubdetails;
            detailsSub = "subsub";
        }

        /* Check AppData */
        if (!('agenda' in localState) || !('agenda' in AppData) || !(AppData.agenda || []).length) {
            Layout.hide('agenda-details');
            return false;
        }
        var
            $details = $('#agenda-' + detailsSub + 'details article'),
            $header  = $('#agenda-' + detailsSub + 'details .data-label'),
            el = AppData.agenda.find(function (el) {
                return el.id === localState.agenda.itemIndex
            }) || AppData.agenda[0],
            day = AppData.days.find(function (day) {
                //return el.day.indexOf(day.id) > -1;
                return day.id == localState.agenda.dayIndex;
            }) || {},
            d = new Date(day.date);
        var date;
            if (localState.lang == "hu") {
                date = days[d.getDay()] + ', ' + d.getFullYear() + '. ' + months[d.getMonth()] + ' ' + d.getDate() + '.';
            } else {
                date = days[d.getDay()] + ', ' + d.getDate() + ' ' + months[d.getMonth()] + ' ' + d.getFullYear();
            }
        $header.text(el.status === 'now' ? Language.getItem('now') : el.status === 'ended' ? Language.getItem('expired') : date);
        $details.find('.data-date').text(date);
        $details.find('.data-timeStart').text(makeTimeFormat(el.timeStart, timeformat.time_format));
        $details.find('.data-timeEnd').text(makeTimeFormat(el.timeEnd, timeformat.time_format));
        /*var room = AppData.rooms.find( function ( room_el ) {
          return room_el.id === el.rooms
        } );*/
        var room = AppData.channels.find(function(e) {
            return e.channel === el.rooms;
        }) || false; // bungi
        $details.find('.data-rooms')
            .attr("data-room-id", el.rooms).text(room ? room.name[localState.lang] : "")
            .parent().toggleClass('hidden', !room);

        var atitle;
        if (typeof el.title == "string") {
            atitle = el.title;
        } else {
            atitle = el.title[localState.lang];
        }

        var children = AppData.agenda.filter(function(e) {
            e.active = (e.active === undefined) ? true : e.active;
            return e.parent === el.id && e.active === true;
        }),
        $fragmentAgenda = $(document.createDocumentFragment());
        $.each(children, function (i, el) {
            var $clone = $templateAgendaItem.clone();
            renderRow($clone, el);
            $clone.appendTo($fragmentAgenda);
            $clone = null;
        });
        if(Boolean(children.length)) {
            $("#agenda-" + detailsSub + "details").attr("data-targetid", el.id);
        }

        $details.find(".join-workshop").hide();

        if (AppData.userbookings) {
            for (var i = 0; i < AppData.userbookings.length; i++) {
                if (AppData.userbookings[i].booking_id == el.booking_id && AppData.userbookings[i].approved) {
                    let tcroom = AppData.userbookings[i].trueconf_room;
                    $details.find(".join-workshop").show().unbind("click").on("click", function () {
                        Bookings.joinTcRoom({}, tcroom);
                    });
                }
            }
        }

        $details.find(".ondemand").hide();

        if (el.ondemand_id) {
            var video = AppData.ondemandvideos.find(function (item) {
                return item.id == el.ondemand_id;
            });

            if (video != undefined) {
                $details.find(".ondemand").show().unbind("click").on("click", function () {
                    Ondemandvideos.showDetails(video.id);
                });
            }
        }

        if (el.broadcast_id && el.broadcast_id != "") {
            $details.find(".join-stream").show().attr("data-bc", el.broadcast_id).unbind("click").on("click", function () {
                let bc_id = $(this).attr("data-bc");
                Layout.hideAll();
                Broadcast.showDetails(bc_id);
            });
        } else {
            $details.find(".join-stream").hide();
        }

        $details.find('.data-title').text(atitle);
        // $details.find( '.data-details' ).html( nl2br(el.details) );
        // children = [];
        $details.find('.childrenagenda').html($fragmentAgenda).toggle(Boolean(children.length));

        $details.find('.data-details').parent().addClass("hidden");

        if (el.details[localState.lang] != "") {
            $details.find('.data-details').parent().removeClass("hidden");
            $details.find('.data-details').html(el.details[localState.lang]);
        }
        $details.find('.item.speaker').remove();
        $details.find('.speakersList').hide();
        if (el.speakers) {

            var fragmentSpeakers = $(document.createDocumentFragment());
            var speakers;
            if (typeof el.speakers == "string") {
                speakers = [el.speakers];
            } else {
                speakers = el.speakers;
            }

            $.each(speakers, function (i, val) {
                var
                    $cloneSpeaker = $templateAgendaSpeaker.clone(),
                    info = [],
                    speaker = AppData.speakers.find(function (el) {
                        return el.id === val.speaker_id
                    });
                if (speaker) {
                    $.each(speaker.values, function(i,e) {
                        if(e.showrow === '1') {
                            info.unshift(typeof e.title == "object" ? e.title[localState.lang] : e.title);
                        } else if(e.showrow === '2') {
                            info.push(typeof e.title == "object" ? e.title[localState.lang] : e.title);
                        }
                    });
                    $cloneSpeaker.attr('data-index', speaker.id);
                    $cloneSpeaker.find('.data-name').text(speaker.name[localState.lang]);
                    $cloneSpeaker.find('.data-info').html(info.join('<br>')).toggleClass('hidden', !info.length);
                    $cloneSpeaker.find('.data-img').attr('src', (speaker.img) ? 'upload/users/' + speaker.img : 'img/profile-picture-placeholder.png');

                    if(AppData.modulsettings.showspeakergotolink === true) {
                        $cloneSpeaker.toggleClass("link");
                    } else {
                        $cloneSpeaker.removeClass("link");
                        $cloneSpeaker.find('.chevron').hide();
                    }

                    $cloneSpeaker.appendTo(fragmentSpeakers);
                }
                $cloneSpeaker = null;
            });
            fragmentSpeakers.appendTo($details.find('.speakersList').show());
            fragmentSpeakers = null;
        }
        $details.find('.item.doc').remove();
        $details.find('.docsList').hide();
        if (el.docs) {
            var fragmentDocs = $(document.createDocumentFragment());
            $.each(el.docs, function (i, val) {
                var
                    $cloneDoc = $templateAgendaDoc.clone(),
                    doc = AppData.docs.find(function (el) {
                        return el.id === val.doc_id
                    });
                if (doc) {
                    $cloneDoc.attr('data-index', doc.id);
                    $cloneDoc.find('.data-title').text(doc.title[localState.lang]);
                    $cloneDoc.appendTo(fragmentDocs);
                }
                $cloneDoc = null;
            });
            fragmentDocs.appendTo($details.find('.docsList').show());
            fragmentDocs = null;
        }
        $details.find('.item.survey').remove();
        $details.find('.surveyList').hide();
        if (el.surveys) {
            var fragmentSurveys = $(document.createDocumentFragment());
            var survey;
            $.each(el.surveys, function (i, val) {
                var $cloneSurvey = $templateAgendaSurvey.clone();

                survey = AppData.surveys.find(function (el) {
                    return el.id === val.survey_id && el.active === true
                });

                if (survey) {
                    $cloneSurvey.attr('data-index', survey.id);
                    $cloneSurvey.find('.data-title').text(survey.title[localState.lang]);
                    $cloneSurvey.appendTo(fragmentSurveys);
                }
                $cloneSurvey = null;
            });
            if (survey) {
                fragmentSurveys.appendTo($details.find('.surveyList').show());
            }
            fragmentSurveys = null;
        }

        $details.find('.item.video').remove();
        $details.find('.videosList').hide();
        if (el.videos) {

            var fragmentVideos = $(document.createDocumentFragment());
            var videos;
            if (typeof el.videos == "string") {
                videos = [el.videos];
            } else {
                videos = el.videos;
            }

            $.each(videos, function (i, val) {
                var
                    $cloneVideo = $templateAgendaVideo.clone(),
                    video = AppData.ondemandvideos.find(function (el) {
                        return el.id === val.v_id
                    });
                if (video) {
                    $cloneVideo.attr('data-index', video.id);
                    $cloneVideo.find('.data-name').text(video.title[localState.lang]);

                    $cloneVideo.appendTo(fragmentVideos);
                }
                $cloneVideo = null;
            });
            fragmentVideos.appendTo($details.find('.videosList').show());
            fragmentVideos = null;
        }

        showFavorite(detailsSub, el.show_add_favorite_button);

        if (AppData.profile.favorite_agenda) {
            var in_favs = AppData.profile.favorite_agenda.find(function (fagenda) {
                return fagenda.agenda == el.id;
            });
        }

        if (in_favs) {
            $("i", $("#agenda-" + detailsSub + "details .favorite_agenda")).removeClass("far").addClass("fa");
        } else {
            $("i", $("#agenda-" + detailsSub + "details .favorite_agenda")).removeClass("fa").addClass("far");
        }

        var issues_visible = AppData.navigation.filter(function (el) {
            return el.module == "issues" && el.visible;
        });

        var notes_visible = AppData.navigation.filter(function (el) {
            return el.module == "notes" && el.visible;
        });

        if (issues_visible.length != 0 && el.show_new_question_button) {
            $("#agenda-" + detailsSub + "details .new-issue").show();
        } else {
            $("#agenda-" + detailsSub + "details .new-issue").hide();
        }

        if (notes_visible.length != 0 && el.show_new_note_button) {
            $("#agenda-" + detailsSub + "details .new-note").show();
        } else {
            $("#agenda-" + detailsSub + "details .new-note").hide();
        }

        $details = $header = el = date = null;
    }

    function showVideo() {
        let index = $(this).attr("data-index");
        Ondemandvideos.showDetails(index);
    }

    /* ----- Event: Change Date --------------------------------------------------------------------------------------- */
    function evtChange() {
        var $self = $('#agenda #agendaList');
        localState.agenda = {
            dayIndex: $(this).val() || 0,
            itemIndex: 0
        };
        $('#agenda .date-wrapper .content').text($('#agenda select.date-selector option:selected').text());
        $self
            .on('animationend', function (e) {
                e.stopPropagation();
                var target = e.target || e.originalEvent.target;
                if (target === this) {
                    $self
                        .off('animationend')
                        .removeClass('fadeOutLeft')
                        .addClass('fadeInRight');
                    renderList();
                    requestAnimationFrame(function () {
                        $self
                            .on('animationend', function () {
                                var target = e.target || e.originalEvent.target;
                                if (target === this) {
                                    $self
                                        .off('animationend')
                                        .removeClass('fadeInRight fadeOutLeft');
                                }
                            });
                    });
                }
            });
        requestAnimationFrame(function () {
            $self.removeClass('fadeInRight').addClass('animated faster fadeOutLeft');
        });
    }

    function toggleItemArrows() {
        if (AppData.modulsettings.agendaopenitems) {
            $("#agenda .item .chevron").show();
        } else {
            $("#agenda .item .chevron").hide();
        }
    }

    /* ----- Event: Click on Item ------------------------------------------------------------------------------------- */
    function evtClick() {
        if (AppData.modulsettings.agendaopenitems) {
            localState.agenda = {
                dayIndex: $('#agenda select.date-selector').val() || $("#agenda .agenda_day_tab.active").attr("data-dayid") || 0,
                itemIndex: String($(this).data('id')) || 0
            };
            localState.agendadetails = String($(this).data('id')) || 0;
            writeDbLog("show details", String($(this).data('id')) || 0, "user_logs", "agenda");
            BuildDetails("1");
            Layout.hide('agenda-details', true);
            Layout.show('agenda-details');
        }
    }

    function evtSubClick() {
        if (AppData.modulsettings.agendaopenitems) {
            localState.agenda = {
                dayIndex: $('#agenda select.date-selector').val() || $("#agenda .agenda_day_tab.active").attr("data-dayid") || 0,
                itemIndex: String($(this).data('id')) || 0
            };
            localState.agendasubdetails = String($(this).data('id')) || 0;
            writeDbLog("show details", String($(this).data('id')) || 0, "user_logs", "agenda");
            BuildDetails("2");
            Layout.hide('agenda-subdetails', true);
            Layout.show('agenda-subdetails');
        }
    }

    function evtSubSubClick() {
        localState.agenda = {
            dayIndex: $('#agenda select.date-selector').val() || $("#agenda .agenda_day_tab.active").attr("data-dayid") || 0,
            itemIndex: String($(this).data('id')) || 0
        };
        localState.agendasubsubdetails = String($(this).data('id')) || 0;
        writeDbLog("show details", String($(this).data('id')) || 0, "user_logs", "agenda");
        BuildDetails("3");
        Layout.hide('agenda-subsubdetails', true);
        Layout.show('agenda-subsubdetails');
    }

    function newIssueClick() {
        Issues.evtClickItem({data: {mod: false, id: localState.agenda.itemIndex}});
    }

    function newNoteClick() {
        var agenda = AppData.agenda.find(function (el) {
            return el.id === localState.agenda.itemIndex;
        });

        var atitle;
        if (typeof agenda.title == "string") {
            atitle = agenda.title;
        } else {
            atitle = agenda.title[localState.lang];
        }

        Notes.evtClickItem({data: {title: agenda ? atitle : ''}});
    }

    function favoriteAgenda() {
        var item = localState.agenda.itemIndex;
        var that = $(this);

        $.ajax({
            method: 'post',
            url: 'api/',
            data: {
                do: 'saveFavoriteAgenda',
                id: item
            },
            success: function(resp) {
                if (resp.status == "added") {
                    $("i", that).removeClass("far").addClass("fa");
                } else {
                    $("i", that).removeClass("fa").addClass("far");
                }
                AppData.profile = resp.profile;
                FavoriteAgenda.Build();
            }
        });
    }

    /* ----- Event: Show Layout --------------------------------------------------------------------------------------- */
    function evtShowLayout() {
        var $self = $('#agenda');
        setTimeout(function () {
            var
                $article = $self.find(AppData.settings.homeScreen === 'classic' ? 'article' : '.scrollbarcontainerbgvideo'),
                elNow = $article.find('.item.now').get(0);
            if (elNow) {
                $article.animate({
                    scrollTop: elNow.offsetTop - screen.availHeight / 3
                }, 300, 'linear');
            }
        }, 300);
    }

    function showFavorite(sub = "", show_add_favorite_button = true) {
        if (AppData.modulsettings.showfavorite && show_add_favorite_button) {
            $("#agenda-" + sub + "details .favorite_agenda").show();
        } else {
            $("#agenda-" + sub + "details .favorite_agenda").hide();
        }
    }

    function UpdateModule(selected_day) {
        let selected_date = $('#agenda select.date-selector option:selected').val();
        if (AppData.modulsettings.agendatabs) {
            selected_date = $('#agenda .agenda_day_tab.active').attr("data-dayid");
        }

        Build(selected_day == undefined ? selected_date : selected_day);
        FavoriteAgenda.Build();
        Issues.Build();
    }

    /* ----- Public API ----------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        evtClickDetails: evtClick,
        showDetails: showDetails,
        showFavorite: showFavorite,
        BuildFromSource: BuildFromSource,
        BuildFromSourceWidthSelectedDay:BuildFromSourceWidthSelectedDay,
        BuildDates: BuildDates,
        Build: Build,
        renderList: renderList,
        toggleRequestSpeakerGotoLink: toggleRequestSpeakerGotoLink,
        toggleItemArrows: toggleItemArrows,
        UpdateModule: UpdateModule
    };
})();
