function EnigmaGame(name, words, prefix, game_area) {

    this.act_letter = 0;
    this.act_time = 0;
    this.container;
    var timer_cont;
    this.enigma_input;
    this.enigma_letters;
    this.enigma_start;
    this.initial = false;
    this.countdown;
    this.letters = "abcdefghijklmn opqrstuvwxyzíűáéúőóüö";
    this.nums = [];
    this.use_poisson = false;
    this.prev_positions;
    this.anim_running = false;
    this.dummy;
    this.clicked_item;
    this.stats = {'finished':false, 'time':'00:00', 'seconds': 0, 'name': ''};
    this.name;
    this.resetbtn;
    this.end_screen;
    this.rotation = 0;
    this.words = words.slice();
    this.act_word = 0;
    this.saving = false;
    this.score = 0;

    var that = this;

    this.init = function() {
        that.act_word = 0;
        that.act_time = 0;
        that.act_letter = 0;
        that.score = 0;
        that.nums = [];

        if (AppData.modulsettings.enigma_words != "") {
            that.words = AppData.modulsettings.enigma_words.split(",");
        } else {
            that.words = words.slice();
        }

        //that.shuffle(that.words);
        //that.words = that.words.splice(0, 4);

        that.container = $('<div/>').attr('id', prefix+'_container').addClass('player_container').appendTo(game_area);
        game_area.css({
            'background-image': 'url("' + url_rewrite + AppData.settings.bgImage + '")'
        });

        that.timerObj = new timer();

        that.timer_cont = $('<div/>').attr('id', prefix+'_timer').addClass('game_timer bg-primary').html('<span>03:00</span>').appendTo(that.container);
        that.enigma_input = $('<div/>').attr('id', prefix+'_enigma_input').addClass('enigma_input').html('<span class="input_inner">'+that.words[that.act_word]+'</span>').appendTo(that.container);
        that.enigma_letters = $('<div/>').attr('id', prefix+'_enigma_letters').addClass('enigma_letters').appendTo(that.container);
        that.dummy = $('<div/>').attr('id', prefix+'_dummy').addClass('dummy').appendTo(game_area);
        that.resetbtn = $('<div/>').attr('id', prefix+'_resetbtn').addClass('enigma_resetbtn').appendTo(that.container);
        that.enigma_start = $('<div/>').attr('id', prefix+'_start').addClass('enigma_start color-primary').html('START').appendTo(that.container);
        that.end_screen = $('<div/>').attr('id', prefix+'_end').addClass('enigma_end').html('' +
            '<div class="final_content">' +
                '<div class="toplist">' +
                    '<div class="toplist_inner">' +
                    '</div>' +
                '</div>' +
                '<div class="final_text color-primary">' +
                    Language.getItem('grat-text') +
                    '<br>'+Language.getItem('game-score')+': <span class="final_score"></span><br>'+
                '</div>' +
            '</div>').appendTo(that.container);

        that.resetbtn.on("click", function(event) {
            event.preventDefault();
            if (event.type == 'touchend') {
                that.resetbtn.off('mouseup');
            }
            that.reset_game();
        });

        $('.save_game', that.container).on("click", function (e) {
            if (!that.saving) {
                that.saving = true;
                $.ajax({
                    url: "save_game",
                    method: 'post',
                    dataType: 'json',
                    data: {
                        name: $(".player_name", that.container).val(),
                        gametime: that.act_time
                    },
                    success: function(response) {
                        that.saving = false;
                        if (response.status == "ok") {
                            $(".final_text", that.container).hide();
                            $(".keyboard", that.container).hide();
                            $(".toplist", that.container).show();
                            let has_actual = [];
                            let toplist = $('.toplist_inner', that.container);
                            toplist.empty();
                            let toplist_item;

                            for (let i in response.top10) {
                                toplist_item = $('<div/>').addClass('toplist_item').html('<div class="place">'+response.top10[i].place+'.</div><div class="name">'+response.top10[i].name+'</div><div class="game_time">'+formatTime(response.top10[i].gametime)+'</div>');
                                for (let j in response.actual_positions) {
                                    if (response.actual_positions[j].login_code == response.top10[i].login_code && response.actual_positions[j].created_at == response.top10[i].created_at) {
                                        toplist_item.addClass('actual');
                                        has_actual.push(response.actual_positions[j].id);
                                    }
                                }

                                toplist_item.appendTo(toplist);
                            }

                            if (response.actual_positions.length != has_actual.length) {
                                toplist_item = $('<div/>').addClass('toplist_item').html('...');
                                toplist_item.appendTo(toplist);
                            }

                            for (let k in response.actual_positions) {
                                if (has_actual.indexOf(response.actual_positions[k].login_code) == -1) {
                                    toplist_item = $('<div/>').addClass('toplist_item later').html('<div class="place">'+response.actual_positions[k].place+'.</div><div class="name">'+response.actual_positions[k].name+'</div><div class="game_time">'+formatTime(response.actual_positions[k].gametime)+'</div>');
                                    toplist_item.appendTo(toplist);
                                }
                            }

                            if (response.top10.length < 10) {
                                for (let i = response.top10.length + 1; i <= 10; i++) {
                                    toplist_item = $('<div/>').addClass('toplist_item').html('<div class="place">'+i+'.</div><div class="name">-</div><div class="game_time"></div>');
                                    toplist_item.appendTo(toplist);
                                }
                            }

                            $(".player_name", that.container).val('');

                            setTimeout(function() {
                                that.reset_game();
                            }, 10000);
                        } else {
                            if (response.fields != undefined) {
                                for (let i in response.fields) {
                                    if (response.fields[i] == true) {
                                        $('#'+i, that.container).addClass("error");
                                    }
                                }
                            }

                        }
                    }
                });
            }
        });

        that.enigma_start.on("click", function(event) {
            event.preventDefault();
            if (event.type == 'touchend') {
                that.enigma_start.off('mouseup');
            }
            that.enigma_start.fadeOut(200);
            that.enigma_input.fadeIn(200);
            that.enigma_letters.fadeIn(200);
            that.timer_cont.fadeIn(200);
            that.timer_cont.addClass("game_started");

            let starttime = AppData.modulsettings.enigma_time || 180

            that.timerObj.init(that.timer_cont, starttime, 'enigmaGame.times_up');
            that.timerObj.stop();
            that.timerObj.start();

            if (AppData.modulsettings.enigma_words != "") {
                that.words = AppData.modulsettings.enigma_words.split(",");
            } else {
                that.words = words.slice();
            }

            that.start();
        });

        for (var i = 1; i <= that.letters.length; i++) {
            that.nums.push(i);
        }

        that.name = name;

        that.generateLetters();
    }

    this.rotate = function(degree) {
        that.rotation = degree;
        if (that.rotation == 360) {
            that.rotation = 0;
        }
        that.container.css({
            transform: 'rotate('+that.rotation+'deg)'
        });
    }

    this.setOnePlayer = function() {
        that.container.css({
            float: 'none'
        });
    }

    this.start = function() {
        /*that.timer.html('<span>00:00</span>');
        var milliseconds = 0;
        that.countdown = setInterval(function() {
            var minutes = parseInt(that.act_time / 60, 10);
            var seconds = parseInt(that.act_time % 60, 10);
            //var hundredths = parseInt(milliseconds % 100, 10);

            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;
            //hundredths = hundredths < 10 ? "0" + hundredths : hundredths;

            that.timer.html("<span>"+minutes + ":" + seconds+"</span>");

            //if (parseInt(milliseconds % 100, 10) == 0 && milliseconds > 0) {
                that.act_time++;
            //}
            //milliseconds++;
        }, 1000);*/

        that.shuffleLetters();
    }

    this.generateLetters = function() {
        var letters_array = that.letters.split("");
        that.shuffle(letters_array);

        that.enigma_letters.empty();

        if (that.use_poisson) {
            var sampler = new PoissonDiskSampler(max_width, max_height, 40, 30);
            var solution = sampler.sampleUntilSolution();
        }

        for (var i = 0; i < letters_array.length; i++) {
            var letter_btn = $('<div/>').attr('data-letter', letters_array[i]).addClass('color-primary border-primary letter letter-'+(i+1)).html(letters_array[i]).appendTo(that.enigma_letters);

            if (that.initial) {
                letter_btn.hide();
            }

            letter_btn.animation = that.letterAnimation(letter_btn, i, solution);
            letter_btn.on('mouseup touchend', that.handleLetterClick);
        }

        /*var space_btn = $('<div/>').attr('data-letter', " ").addClass("letter_space").html("szóköz").appendTo(that.enigma_letters);
        if (that.initial) {
            space_btn.hide();
        }
        space_btn.delay(randInt(100, 800)).fadeIn(400);
        space_btn.on('mouseup touchend', that.handleLetterClick);*/
        $('.letter').on("webkitAnimationEnd oanimationend msAnimationEnd animationend",
            function() {
                $('.shakeit', that.container).removeClass("shakeit");
                $('.letter', that.container).removeClass("delay_move");
            }
        );

        that.dummy.on("transitionend webkitTransitionEnd oTransitionEnd otransitionend MSTransitionEnd", function() {
            $(this).removeClass('dummy_move');
            that.anim_running = false;
        });

        that.initial = false;
        that.prev_positions = solution;
    }

    this.handleLetterClick = function(event) {
        if (!that.anim_running) {
            that.anim_running = true;
            that.dummy.addClass('dummy_move');

            var letter_object = $(event.target);
            that.clicked_item = letter_object;
            if (event.type == 'touchend') {
                letter_object.off('mouseup');
            }

            var selected_letter = letter_object.attr('data-letter');
            if (that.words[that.act_word][that.act_letter].toLowerCase() == selected_letter.toLowerCase()) {
                that.act_letter++;
                var colored = '';
                for (var i = 0; i < that.words[that.act_word].length; i++) {
                    if (i < that.act_letter) {
                        colored += '<span class="green_letter">'+that.words[that.act_word][i]+'</span>';
                    } else {
                        colored += that.words[that.act_word][i];
                    }
                }
                that.enigma_input.html('<span class="input_inner">'+colored+'</span>');
                $('.input_inner', that.enigma_input).css({display: 'inline-block'});
                if (that.act_letter == that.words[that.act_word].length) {
                    that.score += 10;
                    that.act_word++;
                    if (that.act_word < that.words.length) {
                        setTimeout(function() {
                            if (that.act_word == 4) {
                                $(".letter", that.container).addClass("rotateletter");
                            }
                            that.enigma_input.html('<span class="input_inner">'+that.words[that.act_word]+'</span>');
                            $('.input_inner', that.enigma_input).css({display: 'inline-block'});
                            that.act_letter = 0;
                            that.shuffleLetters();
                        }, 1000);
                    } else {
                        that.endgame();
                    }
                    return;
                }
            } else {
                letter_object.addClass('shakeit');
            }

            if (that.use_poisson) {
                that.generateLetters();
            } else {
                that.shuffleLetters();
            }
        }
    }

    this.letterAnimation = function(object, i, solution) {
        if (that.initial) {
            if (that.use_poisson) {
                $(object).css({
                    left: solution[i].x,
                    top: 50 + solution[i].y
                });
            }
            $(object).delay(randInt(100, 800)).fadeIn(400);
        } else {
            if (that.use_poisson) {
                $(object).css({
                    left: prev_positions[i].x,
                    top: 50 + prev_positions[i].y
                });
                $(object).animate({
                    left: solution[i].x,
                    top: 50 + solution[i].y
                }, 500);
            }
        }
    }

    this.shuffleLetters = function() {
        this.shuffle(that.nums);
        var timeout = 0;
        if ($('.letter, .letter_space', that.container).hasClass('shakeit')) {
            timeout = 600;
        }

        setTimeout(function() {
            $('.letter', that.container).removeClass(function (index, css) {
                return (css.match (/(^|\s)letter-\S+/g) || []).join(' ');
            }).each(function (i) {
                $(this).addClass('letter-'+that.nums[i]);
                /*var rotation = randInt(0, 360);
                $(this).css({
                    'transform': 'rotate('+rotation+'deg)'
                });*/
            });
        }, timeout);
    }

    this.reset_game = function() {
        clearInterval(that.countdown);
        that.act_word = 0;
        that.act_time = 0;
        that.act_letter = 0;
        that.score = 0;
        that.timer_cont.html("<span>00:00</span>");

        $(".letter", that.container).removeClass("rotateletter");

        //that.words = words;
        if (AppData.modulsettings.enigma_words != "") {
            that.words = AppData.modulsettings.enigma_words.split(",");
        } else {
            that.words = words.slice();
        }
        /*that.shuffle(that.words);
        that.words = that.words.splice(0, 4);*/

        that.enigma_input.html('<span class="input_inner">'+that.words[that.act_word]+'</span>');
        //that.initial = true;

        that.enigma_start.fadeIn(200);
        that.end_screen.fadeOut(200, function () {
            $('.final_text', that.container).show();
            $(".keyboard", that.container).show();
            $('.toplist', that.container).hide();
        });
        that.enigma_input.fadeOut(200);
        that.enigma_letters.fadeOut(200);
        that.timer_cont.fadeOut(200);
        that.timer_cont.removeClass("game_started");
        //$('.input_inner', that.enigma_input).css({display: 'none'});
    }

    this.shuffle = function(a) {
        var j, x, i;
        for (i = a.length; i; i--) {
            j = Math.floor(Math.random() * i);
            x = a[i - 1];
            a[i - 1] = a[j];
            a[j] = x;
        }
    }

    this.timesUp = function () {
        that.endgame();
    }

    this.endgame = function() {
        clearInterval(that.countdown);

        setTimeout(function() {
            that.enigma_input.fadeOut(200);
            that.enigma_letters.fadeOut(200);
            that.timer_cont.fadeOut(200);
            that.timer_cont.removeClass("game_started");

            $("#enigma .final_score").html(that.score);

            that.end_screen.fadeIn(200, function() {
                $(".letter", that.container).removeClass("rotateletter");
                $.ajax({
                    method: 'post',
                    url: 'api/',
                    data: {
                        do: 'saveEnigma',
                        score: that.score
                    }
                });

                setTimeout(function() {
                    that.reset_game();
                }, 5000);
            });
        }, 1000);
    }
}

var enigmaGame;
function startEnigma() {
    enigmaGame = new EnigmaGame('a', ['Veet', 'Piac', 'Bolt', 'Durex', 'Vírus', 'Siker', 'Dettol', 'Reklám', 'Patika', 'Nurofen', 'Kampány', 'Premium', 'Hatásos', 'Bizalom', 'Piacrész', 'Strepfen', 'Kapszula', 'Vásárlás', 'Eredmény', 'Strepsils', 'Innováció', 'Portfolio', 'Csomagolás', 'Célcsoport', 'Értékesítés', 'Kommunikáció', 'Fertőtlenítő', 'Termékbevezetés', 'Felnőtt fejfájás', 'Stronger together'], 'p1', $('#game_area'));
    enigmaGame.init();
    $(document).on('enigmaGame.times_up', enigmaGame.timesUp);
}
