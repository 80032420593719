var Tetris = (function () {

    let game_hidden = false;
    let clearedLines = 0;

    function Init() {
        $(document).on('mouseup touchend','body', function(event) {
            var e = $.Event("keyup", { keyCode: 39});
            $("body").trigger(e);
            var e = $.Event("keyup", { keyCode: 39});
            $("body").trigger(e);
            var e = $.Event("keyup", { keyCode: 37});
            $("body").trigger(e);
            var e = $.Event("keyup", { keyCode: 38});
            $("body").trigger(e);
        });

        $(document).on('mousedown touchstart','#tetris .right', function(event) {
            event.preventDefault();
            var e = $.Event("keydown", { keyCode: 39});
            $("body").trigger(e);
        });

        $(document).on('mousedown touchstart','#tetris .left', function(event) {
            event.preventDefault();
            var e = $.Event("keydown", { keyCode: 37});
            $("body").trigger(e);
        });

        $(document).on('mousedown touchstart','#tetris canvas', function(event) {
            event.preventDefault();
            var e = $.Event("keydown", { keyCode: 38});
            $("body").trigger(e);
        });

        $('.start_btn', $("#tetris")).on("mouseup touchend", function(e) {
            e.preventDefault();
            $("#tetris .start_content").fadeOut(400);
            startGame();
        });
    }

    function startGame() {

        $("#tetris .tetris_cont, #tetris .tetris_footer").removeClass("hidden");
        game_hidden = false;
        clearedLines = 0;

        $('#tetris .game').blockrain({
            showFieldOnStart: false,
            blockWidth: 15,
            autoBlockWidth: false,
            autoplay: false,
            theme: 'custom',
            difficulty: 'normal',
            scoreText: Language.getItem("game-points"),
            gameOverText: Language.getItem('grat-text'),
            onGameOver: function (score) {
                if (!game_hidden) {
                    $("#tetris .tetris_cont, #tetris .tetris_footer").addClass("hidden");
                    $("#tetris .final_score").html(score);
                    $('#tetris .final_content').fadeIn(400);
                    $.ajax({
                        url: 'api/',
                        method: 'post',
                        data: {
                            do: 'saveTetris',
                            score: score
                        },
                        success: function (response) {
                            setTimeout(function () {
                                $('#tetris .final_content').fadeOut(400);
                                $("#tetris .start_content").fadeIn(400);
                            }, 5000);
                        }
                    });
                }
            },
            onLine: function (lines, scoreIncrement, score) {
                clearedLines += lines;
                let currentSpeed = parseInt($('#tetris .game').blockrain('getspeed'));
                currentSpeed += 4;
                $('#tetris .game').blockrain('setspeed', currentSpeed);
            }
        });

        $('#tetris .game').blockrain('setspeed', 20);
        $('#tetris .game').blockrain('start');
        //$("#tetris .blockrain-start-btn").trigger('click');
        $("#tetris .blockrain-score-holder").appendTo($("#tetris .tetris_footer"));
    }

    function pauseGame() {
        game_hidden = true;
        try {
            $('#tetris .game').blockrain('gameover');
            $("#tetris .tetris_cont, #tetris .tetris_footer").addClass("hidden");
            $("#tetris .start_content").fadeIn(400);
        } catch (e) {
        }
    }

    return {
        Init: Init,
        startGame: startGame,
        pauseGame: pauseGame
    }
})();
