var Notify = (function () {
    /* ----- Init ----------------------------------------------------------------------------------------------------- */
    var layoutToShow;
    var notify;

    function show(data) {
        notify = $('#notify');
        notify.find('.notify-title').text(Language.getItem(data.title));
        notify.find('.notify-text').text(Language.getItem(data.text));
        layoutToShow = data.layoutToShow;
        Layout.show('notify');
    }

    function evtButtonClick() {
        if (layoutToShow) {
            Layout.swap(layoutToShow);
        } else {
            Layout.hide('notify');
        }
    }

    $(document)
        .on('click', '#notify .notify-btn', evtButtonClick);
    /* ----- Public API ----------------------------------------------------------------------------------------------- */
    return {
        show: show
    }
})();
