var CatchIt = function() {
    this.showboxes;
    this.speed = 800;
    this.tl;
    this.game_over = false;
    this.score = 0;
    this.intval;
    this.prev_wrong = 0;
    this.itemSpeed = 6;
    this.items;
    this.saving = false;
    this.container;
    this.timer;
    this.game_started = false;
    this.wrong_count = 0;
    this.catcher;
    this.puff;

    var _this = this;

    this.init = function () {

        _this.game_started = false;

        _this.container = $("#catchit_game_area");
        _this.catcher = $("#catcher");
        _this.items = [];

        _this.puff = $("#puff_anim").contents();
        $("#puff_anim").remove();

        let images = AppData.medias.filter(function(item){
            return item.in_catchit == true;
        });

        for (let im in images) {
            if (images[im].files[localState.lang] != undefined) {
                _this.items.push({
                    "pic": images[im].files[localState.lang].file,
                    "points": images[im].catchit_point,
                    "class": images[im].catchit_point > 0 ? "right" : "wrong"
                });

                if (images[im].catchit_point < 0) {
                    _this.wrong_count++;
                }
            }
        }

        _this.items.sort(function (a, b) {
            return parseInt(a.points) - parseInt(b.points);
        });

        for (var i in _this.items) {
            var imgPre = new Image;
            imgPre.src = _this.items[i].pic;
        }

        //$('.catchit_cont', _this.container).hide();
        $('.start_content', _this.container).show();

        _this.timer = new timer();

        $('.start_btn', _this.container).on("mouseup touchend", function(e) {
            e.preventDefault();
            if (!_this.game_started) {
                _this.game_started = true;

                _this.score = 0;
                _this.prev_wrong = 0;

                let starttime = AppData.modulsettings.catchit_time || 60

                _this.timer.init($('.timer span', _this.container), starttime, 'catch_it.times_up');
                _this.timer.stop();
                _this.timer.start();
                _this.game_over = false;
                $(".collect .points", _this.container).html(_this.score);

                $(".rules").fadeOut(400);
                $(".start_content", _this.container).fadeOut(400, function () {
                    $(".catchit_cont", _this.container).fadeIn(400);
                });
                _this.start();
            }
        });

        /*$(document).on('mousedown touchend', '.clickable', function(e) {
            e.preventDefault();
            var that = $(this);

            var p = $(this).attr("data-points");

            that.removeClass("clickable");
            TweenLite.killTweensOf(that);
            TweenLite.to(that, 0.5, {opacity: 0, scaleX: 0.2, scaleY: 0.2, onComplete: function() {
                $(this.target).remove();
            }});
            _this.increaseScore($(this).attr("data-points"));

        });*/

        let mdowntimer;
        let kdowntimer;
        let keymoving = false;

        $(document).on("keydown", function (e){
            if (!keymoving && _this.game_started) {
                let movement = "+=50";
                let direction = "right";
                keymoving = true;

                switch (e.which) {
                    case 37: // left
                        movement = "-=50";
                        direction = "left";
                        break;
                    case 39: // right
                        movement = "+=50";
                        direction = "right";
                        break;
                    default:
                        return; // exit this handler for other keys
                }

                e.preventDefault();

                clearInterval(kdowntimer);
                kdowntimer = setInterval(function () {
                    _this.moveTruck(movement, direction);
                }, 100);
            }
        });

        $(document).on("keyup", function (){
            clearInterval(kdowntimer);
            keymoving = false;
        });

        $(document).on('mousedown touchstart', '.control_arrow', function(e) {
            e.preventDefault();

            let direction = $(this).attr("data-dir");
            let movement = direction == "right" ? "+=50" : "-=50";

            clearInterval(mdowntimer);
            mdowntimer = setInterval(function (){
                _this.moveTruck(movement, direction);
            }, 100);
        });

        $(document).on('mouseup touchend', '.control_arrow', function(e) {
            e.preventDefault();
            clearInterval(mdowntimer);
        });

        $(window).on('blur', function() {
            gsap.killTweensOf(_this.catcher);
            clearInterval(mdowntimer);
        });

        $(document).on('focus', function() {
            gsap.killTweensOf(_this.catcher);
            clearInterval(mdowntimer);
        });

        $(document).on('blur', function() {
            gsap.killTweensOf(_this.catcher);
            clearInterval(mdowntimer);
        });

        $(document).on('catch_it.times_up', _this.timesUp);
    };

    this.moveTruck = function (movement, direction) {

        $("#catcher .truck").css({
            transform: "rotateY("+(direction == "right" ? 180 : 0)+"deg)"
        });

        gsap.killTweensOf(_this.catcher);
        gsap.to(_this.catcher, {
            duration: 0.1,
            x: movement,
            ease: "none",
            rotateY: (direction == "right" ? 180 : 0),
            modifiers: {
                x: function(x) {
                    return gsap.utils.clamp(0, $(window).width() - _this.catcher.width(), parseInt(x))+"px";
                }
            }
        });
    }

    this.spawnObject = function() {
        if (_this.timer.act_time < 50) {
            _this.itemSpeed = 5;
        }

        if (_this.timer.act_time < 30) {
            _this.itemSpeed = 4;
        }

        if (_this.timer.act_time < 20) {
            _this.itemSpeed = 3;
        }

        var index = randInt(0, (_this.items.length - 1));

        if (_this.items[index].points < 0) {
            _this.prev_wrong++;
        }

        if (_this.prev_wrong % 4 == 0) {
            index = randInt(_this.wrong_count, (_this.items.length - 1));
        }

        var item = $('<img/>').attr("src", _this.items[index].pic).css({
            top: -200,
            left: randInt(0, $(window).width() - 300)
        }).addClass("clickable falling").attr("data-points", _this.items[index].points).addClass(_this.items[index].class);

        $(".fall_container", _this.container).append(item);

        gsap.to(item, {
            duration: _this.itemSpeed,
            y: $(window).height() + 200,
            ease: Linear.easeNone,
            onComplete: function() {
                $(this.targets()[0]).remove();
            },
            onUpdate: function () {
                if (Draggable.hitTest($(this.targets()[0]), "#catcher", 20)) {
                    _this.increaseScore($(this.targets()[0]).attr("data-points"));
                    let puffclone = _this.puff.clone();
                    puffclone.css({
                        top: $("#catcher").position().top - 10,
                        left: $(this.targets()[0]).position().left + ($(this.targets()[0]).width() / 2)
                    });

                    if ($(this.targets()[0]).attr("data-points") < 0) {
                        $("div", puffclone).css({
                            background: "#555555"
                        });
                    }

                    puffclone.appendTo($(".fall_container", _this.container));
                    setTimeout(function (){
                        puffclone.remove();
                    }, 1000);
                    $(this.targets()[0]).remove();
                }
            }
        });

        _this.intval = setTimeout(function() {
            _this.spawnObject();
        }, _this.speed);
    };

    this.increaseScore = function(addscore) {
        _this.score = parseInt(_this.score) + parseInt(addscore);
        $(".collect .points", _this.container).html(_this.score);
    };

    this.start = function() {
        _this.intval = setTimeout(function() {
            _this.spawnObject();
        }, _this.speed);
    };

    this.timesUp = function() {
        clearInterval(_this.intval);
        $('.fall_container', _this.container).empty();

        $("#catchit .final_score").html(_this.score);
        $('#catchit .final_content').fadeIn();

        if (!_this.saving) {
            _this.saving = true;

            $.ajax({
                url: 'api/',
                method: 'post',
                data: {
                    do: 'saveCatchit',
                    score: _this.score
                },
                success: function(response) {
                    _this.saving = false;
                    setTimeout(function (){
                        _this.hideGame();
                    }, 5000);
                }
            });
        }
    };

    this.hideGame = function () {
        //$('.start_btn', _this.container).unbind("mouseup touchend");
        $(".fall_container", _this.container).empty();

        $('#catchit .final_content').hide();
        //$('.catchit_cont', _this.container).hide();
        $('.start_content', _this.container).show();
        _this.game_started = false;
        _this.itemSpeed = 6;

        clearInterval(_this.intval);
        if (_this.timer.timerobj) {
            _this.timer.stop();
        }
    };
};

let catchit_game;
function startCatchit() {
    catchit_game = new CatchIt();
    catchit_game.init();
}

function shuffle(a) {
    var j, x, i;
    for (i = a.length; i; i--) {
        j = Math.floor(Math.random() * i);
        x = a[i - 1];
        a[i - 1] = a[j];
        a[j] = x;
    }
}

function randInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
