var Camerastream = (function () {

    var $templateTranslatorItem;

    var WowzaWebRTCPublish = new wowzawebrtcpublish();
    let state;

    var selectedId;
    var toTurnoff;
    var streamId;
    var webRTCAdaptor;
    var webRTCInitialized = false;
    var publishing = false;

    /* ----- Init --------------------------------------------------------------------------------------------------- */
    function Init() {
        //$templateCameraStreamItem = $('template#tplCameraStreamItem').contents(); // remove().
        //BuildCameraStream();
    }

    function sort() {
        AppData.translator.sort(function (a, b) {
            if (a.sort_id !== undefined && b.sort_id !== undefined) {
                return parseInt(a.sort_id) > parseInt(b.sort_id) ? 1 : -1;
            } else {
                return 0;
            }
        });
    }

    function stopStream() {
        if (publishing) {
            stop();
        }
    }

    function startStream() {

        if (publishing) {
            return;
        }

        streamId = AppData.profile.id;

        state = {
            publishing: false,
            pendingPublish: false,
            pendingPublishTimeout: undefined,
            muted: false,
            video: true,
            selectedCam: '',
            selectedMic: '',
            settings: {
                sdpURL: "wss://streaming2.rentit.hu/webrtc-session.json",
                applicationName: "webrtc",
                streamName: "rca_client_"+streamId+"_"+AppData.settings.site_id,
                audioBitrate: "64",
                audioCodec: "opus",
                videoBitrate: "800",
                videoCodec: "42e01f",
                videoFrameRate: "8",
                frameSize: "default"
            }
        };

        if (!webRTCInitialized) {
            init(onPublishPeerConnected, onPublishPeerConnectionFailed, onPublishPeerConnectionStopped, errorHandler);
            update(state.settings);
        } else {
            update(state.settings);
            start();
        }
    }

    const init = (connected,failed,stopped,errorHandler) => {
        WowzaWebRTCPublish.on({
            gotDevices: () => {
                if (!webRTCInitialized) {
                    webRTCInitialized = true;
                    start();
                }
            },
            onStateChanged: (newState) => {
                console.log("WowzaWebRTCPublish.onStateChanged");
                console.log(newState);

                // LIVE / ERROR Indicator
                if (newState.connectionState === 'connected')
                {
                    connected();
                }
                else if (newState.connectionState === 'failed')
                {
                    failed();
                }
                else
                {
                    stopped();
                }
            },
            onCameraChanged: (cameraId) => {
                if (cameraId !== state.selectedCam)
                {
                    state.selectedCam = cameraId;
                    let camSelectValue = 'CameraMobile_'+cameraId;
                    if (cameraId === 'screen') camSelectValue = 'screen_screen';
                }
            },
            onMicrophoneChanged: (microphoneId) => {
                if (microphoneId !== state.selectedMic)
                {
                    state.selectedMic = microphoneId;
                }
            },
            onError: errorHandler
        });

        WowzaWebRTCPublish.set({
            videoElementPublish: $("#camerastream #cameraStreamVideo"),
            useSoundMeter: false,
            videoEnabled: true,
            constraints: {
                video: {
                    width: 320,
                    height: 240
                }
            }
        });
        //start();
    };

    const getState = () => {
        return state;
    }

    // throw errors with these messages
    const okToStart = () => {
        if (state.settings.sdpURL === "")
        {
            throw "No stream configured.";
        }
        else if (state.settings.applicationName === "")
        {
            throw "Missing application name.";
        }
        else if (state.settings.streamName === "")
        {
            throw "Missing stream name."
        }
        return true;
    }

    const updateFrameSize = (frameSize) => {
        let constraints = JSON.parse(JSON.stringify(WowzaWebRTCPublish.getState().constraints));
        if (frameSize === 'default')
        {
            constraints.video["width"] = { min: "640", ideal: "1280", max: "1920" };
            constraints.video["height"] = { min: "360", ideal: "720", max: "1080" };
        }
        else
        {
            constraints.video["width"] = { exact: frameSize[0] };
            constraints.video["height"] = { exact: frameSize[1] };
        }
        WowzaWebRTCPublish.set({constraints: constraints});
    }

    const update = (settings) => {
        state.settings = settings;
        return WowzaWebRTCPublish.set(settings);
    }

    // start/stop publisher
    const start = () => {
        if(okToStart()){
            WowzaWebRTCPublish.start();
            publishing = true;
            $('.open-main[data-target="camerastream"]').addClass("red");
            Modal.alert("Camera started.");
        }
    };

    const stop = (is_error) => {
        WowzaWebRTCPublish.stop();
        publishing = false;
        $('.open-main[data-target="camerastream"]').removeClass("red");
        if (!is_error) {
            Modal.alert("Camera stopped.");
        }
    };

    const videoOn = () => {
        WowzaWebRTCPublish.setVideoEnabled(true);
    }
    const videoOff = () => {
        WowzaWebRTCPublish.setVideoEnabled(false);
    }

    const audioOn = () => {
        WowzaWebRTCPublish.setAudioEnabled(true);
    }

    const audioOff = () => {
        WowzaWebRTCPublish.setAudioEnabled(false);
    }

    // Helpers

    const errorHandler = (error) => {
        let message;
        if(error.name == "OverconstrainedError"){
            message = "Your browser or camera does not support this frame size";
            updateFrameSize("default");
        } else if ( error.message ) {
            message = error.message;
        }
        else {
            message = error
        }
        console.log(message);
        Modal.alert(message);
        stop(true);
    };

    const setPendingPublish = (pending) =>
    {
        if (pending)
        {
            state.pendingPublish = true;
            state.pendingPublishTimeout = setTimeout(()=>{
                stop();
                errorHandler({message:"Publish failed. Unable to connect."});
                setPendingPublish(false);
            },10000);
        }
        else
        {
            state.pendingPublish = false;
            if (state.pendingPublishTimeout != null)
            {
                clearTimeout(state.pendingPublishTimeout);
                state.pendingPublishTimeout = undefined;
            }
        }
    }

    const onPublishPeerConnected = () => {
        state.publishing = true;
        setPendingPublish(false);
    }

    const onPublishPeerConnectionFailed = () => {
        setPendingPublish(false);
        onPublishPeerConnected();
        errorHandler({message:"Peer connection failed."});
    }

    const onPublishPeerConnectionStopped = () => {
        if (!state.pendingPublish)
        {
            state.publishing = false;
        }
    }

    function turnOffSignal() {
        //Socket.Send("Soundchannels.streamFinished", toTurnoff, {client: "client"});
        streamId = undefined;
        selectedId = undefined;
        toTurnoff = undefined;
    }

    function showDetails(id) {
        if (id) {

        }
    }

    /* ----- Init --------------------------------------------------------------------------------------------------- */
    function BuildFromSource() {

        $.post('api/', {
            do: 'getTranslator'
        }, function (data) {
            AppData.translator = data;
            Init();
        });
    }

    /* ----- Public API --------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        BuildFromSource: BuildFromSource,
        showDetails: showDetails,
        startStream: startStream,
        stopStream: stopStream
    }
})();
