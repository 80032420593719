/*
 Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 Unauthorized copying of this file, via any medium is strictly prohibited
 Proprietary and confidential

 */
var ChangePass = (function () {
    function Init() {
        var $header = $('#changepass-details .data-label');
        $header.text(Language.getItem('profile-change-pass'));
    }

    $(document).on('submit', '#changepass-details #formChangePass', function (e) {
        e.preventDefault();
        $.post('api/', {
            do: 'changePassword',
            data: $(this).serializeObject()
        }, function (data) {
            if (data.status === "ok") {
                Modal.alert(Language.getItem(data.message), function(){
                    Layout.hide("changepass-details");
                });

            } else {
                Modal.alert(Language.getItem(data.message));
            }
        });
    });

    $(document).on('click', '#changepass-details .showpass', function(e) {
        e.target.previousElementSibling.type = "text";
        setTimeout(function () {
            e.target.previousElementSibling.type = "password";
        }, 2000);
    });

    return {
        Init: Init
    };
})();
