/**
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */
var Stage = (function () {
    'use strict';
    /* ----- Declare Variables ---------------------------------------------------------------------------------------- */
    var $templateStageItem, filter;

    /* ----- Init ----------------------------------------------------------------------------------------------------- */
    function Init() {
        $templateStageItem = $('template#tplStageItem').remove().contents();
        filter = 'all';
        $('.filter-all').addClass('bg-primary');
        renderList();
        delete Stage.Init;
    }

    /* ----- Build Docs List ---------------------------------------------------------------------------------------- */
    function renderList() {
        var
            $self = $('#stage .stageList, #landing .landing_elements .stage_cont .stageList'),
            $fragment = $(document.createDocumentFragment());
        var user_agenda = AppData.agenda.filter(function (el) {
            if (AppData.profile.group == "director") {
                return true;
            }

            if (el.speakers) {
                var speakers = el.speakers;
                for (var i = 0; i < speakers.length; i++) {
                    if (speakers[i].speaker_id == AppData.profile.id) {
                        return true;
                    }
                }
            }
            return false;
        });

        var user_rooms = [];
        $.each(user_agenda, function (i, el) {
            user_rooms.push(el.rooms);
        });

        var issues_to_answer = AppData.issues.filter(function (el) {
            if (el.room_id && el.active) {
                switch (filter) {
                    case 'all':
                        return (((user_rooms && ($.inArray(el.room_id, user_rooms) > -1 || el.room_id == user_rooms)) || AppData.profile.group == "director" || el.room_id === 'all') && el.approved);
                        // return false;
                        break;
                    case 'answered':
                        //return el.approved && el.answered;
                        return (((user_rooms && ($.inArray(el.room_id, user_rooms) > -1 || el.room_id == user_rooms)) || AppData.profile.group == "director" || el.room_id === 'all') && el.approved && el.answered);
                        break;
                    case 'denied':
                        //return el.approved && el.answered === false;
                        return (((user_rooms && ($.inArray(el.room_id, user_rooms) > -1 || el.room_id == user_rooms)) || AppData.profile.group == "director" || el.room_id === 'all') && el.approved && el.answered === false);
                        break;
                    case 'waiting':
                        //return el.approved && el.answered === undefined;
                        return (((user_rooms && ($.inArray(el.room_id, user_rooms) > -1 || el.room_id == user_rooms)) || AppData.profile.group == "director" || el.room_id === 'all') && el.approved && el.answered === undefined);
                        break;
                }
            }
            return false;
        });
        issues_to_answer.sort(function (a, b) {
            if (a.approve_date == undefined || b.approve_date == undefined) return -1;

            return b.approve_date - a.approve_date;
        });
        $.each(issues_to_answer, function (i, el) {
            var $clone = $templateStageItem.clone();
            $clone.attr('data-index', el.id);

            var speaker = AppData.speakers.find(function (s) {
                return s.id === el.speaker;
            });

            /*var from = AppData.attendees.find(function (s) {
                return s.id === el.user;
            });*/

            var from = el.username[localState.lang];

            var agenda = AppData.agenda.find(function (a) {
                return a.id === el.agenda;
            });

            var qtext = "";
            if (AppData.modulsettings.qandashowprogram) {
                qtext += agenda ? "Agenda: " + agenda.title[localState.lang] + '<br>' : "";
            }
            qtext += speaker ? "To: " + speaker.name[localState.lang] + '<br>' : "";
            qtext += from ? "From: " + (el.is_anonym ? "Anonymous" : from) + '<br>' : "";
            qtext += "<br><span class='issues-title'>Question:</span> " + el.question;
            qtext += el.answer ? "<div class='answer_cont'><span class='issues-title'>Answer:</span> " + el.answer + "</div>" : "";
            qtext += el.note ? "<div class='note_cont'><span class='issues-title'>Note:</span> " + el.note + "</div>" : "";

            $clone.find('.data-question').html(qtext);
            $clone.appendTo($fragment);
            if (el.answered === undefined && AppData.modulsettings.qandaapprove_by_speaker === false) {
                $clone.find('.answer-btn').addClass('hidden');
            }
            if (el.answered) {
                $clone.find('.answer-btn').addClass('hidden');
                $clone.addClass('answered');
                $clone.children('i').first().removeClass('fa-question').addClass('fa-check');
            }
            if (el.answered === false) {
                $clone.find('.deny-btn').addClass('hidden');
                $clone.addClass('denied');
                $clone.children('i').first().removeClass('fa-question').addClass('fa-times');
            }
        });
        $self.html($fragment);
        var answer_btn_text = Language.getItem('stage-answer');
        $('.stage-answer').text(answer_btn_text);
        var deny_btn_text = Language.getItem('stage-deny');
        $('.stage-deny').text(deny_btn_text);
        $('#stage').find('.no-result').toggle(Boolean(!AppData.issues.length));
    }

    function evtApproveClick() {
        var issue_id = $(this).closest('.item').attr('data-index');
        $(this).prop('disabled', true);
        $.post('api/', {
            do: 'answerIssues',
            data: {
                id: issue_id,
                answered: true
            }
        }, function (data) {
            AppData.issues = data;
            renderList();

            /*Socket.Send('Issues.BuildFromSource', true, {
                client: 'issues'
            });

            Socket.Send('Stage.BuildFromSource', true);*/

            Socket.Send("refreshModule", {
                module: ['Issues', 'Stage'],
                appdata: 'issues',
                data: data,
                callback: ['Build', 'renderList']
            }, {client: ['client', 'issues']});
        })
    }

    function evtDenyClick() {
        var issue_id = $(this).closest('.item').attr('data-index');
        $(this).prop('disabled', true);
        $.post('api/', {
            do: 'answerIssues',
            data: {
                id: issue_id,
                answered: false
            }
        }, function (data) {
            AppData.issues = data;
            renderList();

            /*Socket.Send('Issues.BuildFromSource', true, {
                client: 'issues'
            });

            Socket.Send('Stage.BuildFromSource', true);*/

            Socket.Send("refreshModule", {
                module: ['Issues', 'Stage'],
                appdata: 'issues',
                data: data,
                callback: ['Build', 'renderList']
            }, {client: ['client', 'issues']});
        })
    }

    function evtFilterClick(e) {
        $("button[class*='filter']").removeClass('bg-primary');
        $(this).addClass('bg-primary');
        filter = e.data.filter;
        renderList();
    }

    function BuildFromSource() {
        $.post('api/', {
            do: 'getIssues'
        }, function (data) {
            AppData.issues = data;
            renderList();
        });
    }

    function Build() {
        renderList();
    }

    function UpdateModule(data) {
        AppData.issues = data;
        renderList();
    }

    function notify(data) {
        if (user_rooms.indexOf(data.room_id) > -1) {
            Notify.show(data);
        }
    }

    $(document)
        .on('click', '#stage .item .answer-btn, #landing .stage_cont .item .answer-btn', evtApproveClick)
        .on('click', '#stage .item .deny-btn, #landing .stage_cont .item .deny-btn', evtDenyClick)
        .on('click', '#stage .filter-all, #landing .stage_cont .filter-all', {filter: 'all'}, evtFilterClick)
        .on('click', '#stage .filter-answered, #landing .stage_cont .filter-answered', {filter: 'answered'}, evtFilterClick)
        .on('click', '#stage .filter-waiting, #landing .stage_cont .filter-waiting', {filter: 'waiting'}, evtFilterClick)
        .on('click', '#stage .filter-denied, #landing .stage_cont .filter-denied', {filter: 'denied'}, evtFilterClick);
    return {
        Init: Init,
        BuildFromSource: BuildFromSource,
        Build: Build,
        renderList: renderList,
        UpdateModule: UpdateModule,
        notify: notify
    }
})();
