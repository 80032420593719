var Modal = (function () {
    'use strict';
    /* ----- Declare Variables ---------------------------------------------------------------------------------------- */
    var
        $layoutMain,
        fnCallback,
        dismissCallbackFn,
        defaultClass = "icon-main far fa-window-restore";

    /* ----- Init ----------------------------------------------------------------------------------------------------- */
    function Init() {
        $layoutMain = $('#modal');
        $layoutMain.hide();
        $(document).on('click', '#modal .btn.accept, #modal .btn.close', function () {
            $layoutMain.hide();
            if (typeof fnCallback === 'function') {
                fnCallback.call();
                fnCallback = null;
            }
        });
        $(document).on('click', '#modal .btn.dismiss', function () {
            $layoutMain.hide();

            if (typeof dismissCallbackFn === 'function') {
                dismissCallbackFn.call();
                dismissCallbackFn = null;
            }
        });
        delete Modal.Init;
    }

    /* ----- Alert ---------------------------------------------------------------------------------------------------- */
    function alert(text, callback) {

        $layoutMain.removeClass("dark");
        $layoutMain.find('.image').removeClass("shown");
        $layoutMain.find('.modalchk_cont').hide();

        $layoutMain.addClass("small_popup");
        $layoutMain.find("article").addClass("small_popup");

        $layoutMain.find(".icon_cont").show();
        $layoutMain.find(".icon_cont i").removeClass().addClass(defaultClass);

        fnCallback = callback;
        $layoutMain.find('.title').text(Language.getItem('modal-alert'));
        $layoutMain.find('.text').html(text);
        $layoutMain.find('.btn.accept').hide();
        $layoutMain.find('.btn.dismiss').hide();
        $layoutMain.find('.modal_closebutton').text(Language.getItem('close'));
        $layoutMain.find('.btn.close').show();
        $layoutMain.show();
    }

    /* ----- Confirm -------------------------------------------------------------------------------------------------- */
    function confirm(text, callback) {

        $layoutMain.find(".icon_cont").show();
        $layoutMain.find(".icon_cont i").removeClass().addClass(defaultClass);

        $layoutMain.removeClass("dark");
        $layoutMain.find('.modalchk_cont').hide();
        $layoutMain.find('.image').removeClass("shown");

        fnCallback = callback;
        $layoutMain.find('.title').text(Language.getItem('modal-confirm'));
        $layoutMain.find('.text').text(text);
        $layoutMain.find('.btn.accept').show();
        $layoutMain.find('.btn.dismiss').show();
        $layoutMain.find('.btn.close').hide();
        $layoutMain.show();
    }

    function confirmWithDismiss(text, callback, dismisscallback) {
        $layoutMain.find(".icon_cont").show();
        $layoutMain.find(".icon_cont i").removeClass().addClass(defaultClass);

        $layoutMain.removeClass("dark");
        $layoutMain.find('.modalchk_cont').hide();
        $layoutMain.find('.image').removeClass("shown");

        fnCallback = callback;
        dismissCallbackFn = dismisscallback;
        $layoutMain.find('.title').text(Language.getItem('modal-confirm'));
        $layoutMain.find('.text').text(text);
        $layoutMain.find('.btn.accept').show();
        $layoutMain.find('.btn.dismiss').show();
        $layoutMain.find('.btn.close').hide();
        $layoutMain.show();
    }

    /* ----- Notification --------------------------------------------------------------------------------------------- */
    function notification(data, callback) {
        fnCallback = callback;

        if (data.icon != "" || data.icon != undefined) {
            $layoutMain.find(".icon_cont i").removeClass().addClass("icon-main").addClass(data.icon);
            $layoutMain.find(".icon_cont").show();
        } else {
            $layoutMain.find(".icon_cont").hide();
        }

        if (data.small_popup) {
            $layoutMain.addClass("small_popup");
            $layoutMain.find("article").addClass("small_popup");
        } else {
            $layoutMain.removeClass("small_popup");
            $layoutMain.find("article").removeClass("small_popup");
        }

        $layoutMain.removeClass("dark");
        $layoutMain.find('.modalchk_cont').hide();

        $layoutMain.find('.title').text(data.title[localState.lang]);
        $layoutMain.find('.text').html(data.text[localState.lang]);

        if(data.closebuttontext !== undefined && data.closebuttontext[localState.lang] !== "") {
            $layoutMain.find('.modal_closebutton').text(data.closebuttontext[localState.lang]);
        } else {
            $layoutMain.find('.modal_closebutton').text(AppData.languages[localState.lang].close);
        }

        var files = data.files || undefined;
        $layoutMain.find('.image').removeClass("shown");

        if (files && files[localState.lang] != undefined) {
            $layoutMain.find('.image').html('<img src="'+files[localState.lang].path+'">').addClass("shown");
        }

        $layoutMain.find('.btn.accept').hide();
        $layoutMain.find('.btn.dismiss').hide();
        if (data.canClose) {
            $layoutMain.find('.btn.close').show();
        } else {
            $layoutMain.find('.btn.close').hide();
        }

        if (data.has_checkbox) {
            $layoutMain.find('.modalchk_cont').show();
            if(data.checkboxtext !== undefined && data.checkboxtext[localState.lang] !== "") {
                $layoutMain.find('.checkboxtext').text(data.checkboxtext[localState.lang]);
            } else {
                $layoutMain.find('.checkboxtext').text(AppData.languages[localState.lang].checkboxtext);
            }
        } else {
            $layoutMain.find('.modalchk_cont').hide();
        }

        if (data.darkmode) {
            $layoutMain.addClass("dark");
        } else {
            $layoutMain.removeClass("dark");
        }

        $layoutMain.show();
    }

    function resultconfirmation(data, callback) {
        fnCallback = callback;

        $layoutMain.removeClass("dark");
        $layoutMain.find('.modalchk_cont').hide();

        $layoutMain.find('.title').text(data.title);
        $layoutMain.find('.text').text(data.text);
        $layoutMain.find('.btn.accept').hide();
        $layoutMain.find('.btn.dismiss').hide();
        if (data.canClose) {
            $layoutMain.find('.btn.close').show();
        } else {
            $layoutMain.find('.btn.close').hide();
        }
        $layoutMain.show();
    }

    /* ----- Public API ----------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        alert: alert,
        confirm: confirm,
        notification: notification,
        resultconfirmation: resultconfirmation,
        confirmWithDismiss: confirmWithDismiss
    }
})();
