var Game = (function () {
    'use strict';
    /* ----- Declare Valiables ---------------------------------------------------------------------------------------- */
    var points = 0;

    /* ----- Declare Valiables ---------------------------------------------------------------------------------------- */
    function Init() {
        points = 0;
        //$('main#game').find('header .close-main').hide();

    }

    /* ----- Reser Form ----------------------------------------------------------------------------------------------- */
    function resetForm() {
        var $game = $('#game form');
        $game.find('input[type=hidden]').val('');
        $game.find('input[type=radio], input[type=checkbox]').prop('checked', false);
        $game.find('label').removeClass('state-checked animated pulse');
        $game.find('.fa-star').removeClass('fas').addClass('far');
        $game.find('textarea').val('');
        $game.find('.state-checked').removeClass('state-checked');
        $game.find('[type=submit]').addClass('disabled');
    }

    function evtClickStar() {
        var $this = $(this);
        $this
            .prevAll().addBack()
            .removeClass('far').addClass('fas');
        $this
            .nextAll()
            .removeClass('fas').addClass('far');
        $this.closest('.item').find('input').val($this.index() + 1).trigger('change');
    }

    /* ----- Event: Submit Form --------------------------------------------------------------------------------------- */
    function evtSubmitForm(e) {
        e.preventDefault();
        $(this).hide();
        $(this).closest('div.main').find('.done').show();
        var result = $("#game form").serializeArray();


        $.ajax({
            method: 'post',
            url: 'api/',
            data: {
                do: 'saveMeeVet',
                meevet: meevet_result
            }
        });

        //Points.sendExhibitionPoints(points);
        //points = 0;
        resetForm();
    }

    /* ----- Event: Change Input -------------------------------------------------------------------------------------- */
    function evtChangeInput() {
        var
            classList = 'state-checked animated pulse',
            $label = $(this).closest('label');
        if (this.type === 'radio') {
            $label.siblings().removeClass(classList);
        }
        $label.toggleClass(classList, this.checked);
        points = ($('#game').find('.exhibition_quiz .state-checked.correct').length || 0) * 100;
        $('#game').find('[type=submit]').toggleClass('disabled', $('#game').find('.exhibition_quiz .state-checked').length !== 5);
    }

    /* ----- Bind Events ---------------------------------------------------------------------------------------------- */
    $(document)
        .on('change', '#game input[type=radio], #game input[type=checkbox]', evtChangeInput)
        .on('click', '#game .survey-star .fa-star', evtClickStar)
        .on('submit', '#game form', evtSubmitForm);
    /* ----- Public API ----------------------------------------------------------------------------------------------- */
    return {
        Init: Init
    };
})();
